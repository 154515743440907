<template>
  <div>
    <b-button v-b-modal.addCouponModal v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-success" block>
      เพิ่มคูปอง
    </b-button>

    <b-modal v-model="modal" size="lg" id="addCouponModal" title="เพิ่มคูปอง">

      <b-form>
        <div class="d-flex flex-row align-items-center">
          <b-form-group class="mr-1 flex-grow-1" label="Code" label-for="code">
            <b-form-input v-model="form.code"></b-form-input>
          </b-form-group>
          <b-button @click="generateRandomString()" v-ripple.400="'rgba(113, 102, 240, 0.15)'" style="margin-top: 7px"
            variant="gradient-primary">
            สุ่ม
          </b-button>
        </div>

        <div class="row">
          <div class="col-3">
            <b-form-group label="ประเภท" label-for="type_id">
              <b-form-select v-model="form.type" :options="couponTypes"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group label="มูลค่า" label-for="cashback">
              <b-form-input v-model="form.reward"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group label="Turn (จำนวนเงิน)" label-for="cashback">
              <b-form-input v-model="form.turnover"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group label="จำนวน" label-for="cashback">
              <b-form-input v-model="form.amount" type="number"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="เริ่ม" label-for="start">
              <flat-pickr id="start" v-model="form.start" class="form-control"/>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="หมดอายุ" label-for="end">
              <flat-pickr id="end" v-model="form.end" class="form-control"/>
            </b-form-group>
          </div>
        </div>
      </b-form>

      <template #modal-footer>
        <b-button variant="success" @click="submit()">
          ยืนยัน
        </b-button>
        <b-button variant="danger" @click="cancel()">
          ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone';
import 'flatpickr/dist/flatpickr.css';
export default {
  components: {
    BForm, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    flatPickr
  },
  directives: {
    Ripple
  },
  emits: ['submit'],
  data: () => ({
    modal: false,
    couponTypes: ['MONEY', 'DIAMOND'],
    form: {
      code: '',
      type: 'MONEY',
      reward: '',
      turnover: '',
      start: '',
      end: '',
      amount: 1
    }
  }),
  mounted() {
    this.generateRandomString()
  },
  methods: {
    submit() {
      this.$emit('submit', this.form)
      this.modal = false
    },
    cancel() {
      this.modal = false;

      this.date = new Date();
      this.close_at = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 15, 0);
    },
    generateRandomString() {
      const length = 10;
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      this.form.code = result;
    }
  }
}
</script>